import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import { Container } from 'components/layout';
import Image from 'components/image';
import Leader from 'components/leader';

import s from './Certifications.scss';

export default class Certifications extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    backgroundColor: PropTypes.oneOf(['white', 'grey']),
    ctas: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    certifications: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
        heading: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }

  static defaultProps = {
    heading: '',
    text: '',
    ctas: [],
    certifications: [],
    backgroundColor: 'white',
  }

  render() {
    const { heading, text, ctas, certifications, backgroundColor } = this.props;

    return (
      <div className={s('certifications', backgroundColor)}>
        <div className={s.certifications__inner}>
          <div className={s.certifications__intro}>
            <Leader className={s.certifications__leader} centered heading={heading} text={text}>
              {ctas && (
                <div className={s.certifications__buttons}>
                  {ctas.map(cta => (
                    cta.url && (
                      <div className={s.certifications__buttonWrap} key={cta.text}>
                        <Button key={cta.url + cta.text} to={cta.url} color="transparent">
                          {cta.text}
                        </Button>
                      </div>
                    )
                  ))}
                </div>
              )}
            </Leader>
          </div>

          <Container>
            <div className={s.certifications__row}>
              {certifications.map(certification => (
                <div
                  key={certification.heading}
                  className={s.certifications__item}
                >
                  {certification.image &&
                    <div className={s.certifications__image}>
                      <Image
                        src={certification.image.src}
                        alt={certification.image.alt}
                        width={certification.image.width}
                        height={certification.image.height}
                        transition="none"
                      />
                    </div>
                  }
                  {certification.heading &&
                    <h2 className={s.certifications__itemHeading}>
                      {certification.heading}
                    </h2>
                  }
                  {certification.text &&
                    <p className={s.certifications__text}>
                      {certification.text}
                    </p>
                  }
                </div>
              ))}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
