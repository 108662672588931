import React, { PureComponent, Fragment } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';

import Modules from 'containers/modules';
import Hero from 'components/hero';
import GreyArea from 'components/grey-area';
import Certifications from 'components/certifications';

import CardPair from './components/card-pair';
import s from '../../styles/spacing.scss';
import SEO from 'components/seo';

const hasLetters = text => text.search(/[A-Za-z]+/) >= 0 
class Solution extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    const { data, ui } = this.props;

    ui.setNavTheme(data.page.darkHeader ? 'dark' : 'light');
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark');
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;

    return (
      <Fragment>
        <SEO pathname={pathname} {...extractMeta(page)} article />
        <Hero
          centered
          theme={page.darkHeader ? 'dark' : 'light'}
          heading={page.heroHeading}
          text={_get(page.heroText, 'text')}
          videoBg={_get(page, 'heroVideoBg.file.url')}
          poster={_get(page.heroBackgroundImage, 'file.url')}
        />

        {page.introImage &&
          <LazyLoad once offset={500}>
            <Modules list={[page.introImage]} />
          </LazyLoad>
        }
        {page.imageSet &&
          <LazyLoad once offset={500}>
            <Modules list={[page.imageSet]} />
          </LazyLoad>
        }
        <GreyArea
          heading={page.greyHeading}
          text={_get(page.greyText, 'text')}
          leaderCentered
        >
          {page.greyModules &&
            <Modules list={page.greyModules} />
          }
          {page.cardPairHeading &&
            <LazyLoad once offset={500}>
              <CardPair
                heading={page.cardPairHeading}
                cards={page.cardPairLinks && page.cardPairLinks.map(card => ({
                  image: {
                    src: _get(card, 'image.file.url'),
                    alt: _get(card, 'image.description'),
                    width: _get(card, 'image.file.details.image.width'),
                    height: _get(card, 'image.file.details.image.height'),
                  },
                  heading: card.heading,
                  text: _get(card, 'text'),
                  ctaText: _get(card, 'ctaText'),
                  ctaUrl: `/products/${_get(card, 'slug')}`,
                }))}
              />
            </LazyLoad>
          }
          {page.cardPairHeading2 &&
            <LazyLoad once offset={500}>
              <CardPair
                heading={page.cardPairHeading2}
                cards={page.cardPairLinks2 && page.cardPairLinks2.map(card => ({
                  image: {
                    src: _get(card, 'image.file.url'),
                    alt: _get(card, 'image.description'),
                    width: _get(card, 'image.file.details.image.width'),
                    height: _get(card, 'image.file.details.image.height'),
                  },
                  heading: card.heading,
                  text: _get(card, 'text'),
                  ctaText: _get(card, 'ctaText'),
                  ctaUrl: `/products/${_get(card, 'slug')}`,
                }))}
              />
            </LazyLoad>
          }
        </GreyArea>

        {page.iconSet !== null && <Modules list={[page.iconSet]} />}

        {page.testimonial !== null && (
          <div className={s.contain}>
            <GreyArea>
              <Modules list={[page.testimonial]} />
            </GreyArea>
          </div>
        )}

        {page.apiCta !== null && <Modules list={[page.apiCta]} />}

        {page.certificationsList?.length > 1 &&
          <LazyLoad once offset={500}>
            <Certifications
              backgroundColor="grey"
              heading={page.certificationsHeading}
              text={_get(page.certificationsText, 'text')}
              ctas={[
                {
                  text: page.certificationsCtaText,
                  url: page.certificationsCtaUrl,
                },
                {
                  text: page.certificationsCtaText2,
                  url: page.certificationsCtaUrl2,
                },
              ]}
              certifications={page.certificationsList.map(certification => ({
                image: {
                  src: _get(certification, 'image.file.url'),
                  alt: _get(certification, 'image.description'),
                  width: _get(certification, 'image.file.details.image.width'),
                  height: _get(certification, 'image.file.details.height'),
                },
                heading: _get(certification, 'heading'),
                text: _get(certification, 'text.text'),
              }))}
            />
          </LazyLoad>
        }

        {(page.productCta !== null && hasLetters(page.productCta.ctaText)) && <Modules list={[page.productCta]} />}
      </Fragment>
    );
  }
}

export default withUIContext(Solution);
