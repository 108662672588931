import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'components/layout';

import CardPairItem from './CardPairItem';

import s from './CardPair.scss';

export default class CardPair extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
        heading: PropTypes.string,
        text: PropTypes.string,
        ctaText: PropTypes.string,
        ctaUrl: PropTypes.string,
      }),
    ),
  }

  static defaultProps = {
    heading: '',
    cards: [],
  }

  render() {

    const { heading, cards } = this.props;

    return (
      <div className={s.cardPair}>
        <Container>
          <h2 className={s.cardPair__heading}>{heading}</h2>
          <Row>
            {cards.map(card => (
              <div key={card.heading} className={s.cardPair__card}>
                <CardPairItem
                  key={card.heading}
                  image={card.image}
                  heading={card.heading}
                  text={card.text}
                  ctaText={card.ctaText}
                  ctaUrl={card.ctaUrl}
                />
              </div>
            ))}
          </Row>
        </Container>
        <hr className={s.cardPair__hr} />
      </div>
    );
  }
}
