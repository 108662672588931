import { graphql } from 'gatsby';
import Solution from 'routes/solution/Solution';

export default Solution;

export const query = graphql`
  query Solution($slug: String!) {
    page: contentfulTemplateSolution(slug: { eq: $slug }) {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      darkHeader
      heroBackgroundImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody {
        text: heroBody
      }
      heroVideoBg {
        file {
          url
        }
      }
      introImage {
        ...moduleList
      }
      imageSet {
        ...moduleList
      }
      greyHeading: greyTitle
      greyText: greyBody {
        text: greyBody
      }
      greyModules: modules {
        ...moduleList
      }
      cardPairHeading: cardPairTitle
      cardPairLinks {
        image: previewImage {
          ...image
        }
        heading: previewTitle
        text: previewBody
        ctaText: pageTitle
        slug
      }
      cardPairHeading2: cardPairTitle2
      cardPairLinks2 {
        image: previewImage {
          ...image
        }
        heading: previewTitle
        text: previewBody
        ctaText: pageTitle
        slug
      }
      iconSet {
        ...moduleList
      }
      testimonial {
        ...moduleList
      }
      apiCta {
        ...moduleList
      }
      certificationsHeading: certificationsTitle
      certificationsText: certificationsBody {
        text: certificationsBody
      }
      certificationsCtaText
      certificationsCtaUrl
      certificationsCtaText2
      certificationsCtaUrl2
      certificationsList {
        ...submoduleList
      }
      productCta {
        ...moduleList
      }
    }
  }
`;
