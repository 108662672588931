import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Button from 'components/button';

import s from './CardPairItem.scss';

export default class CardPair extends PureComponent {

  static propTypes = {
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    heading: PropTypes.string,
    text: PropTypes.string,
    ctaUrl: PropTypes.string,
    ctaText: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
  }

  render() {

    const { image, heading, text, ctaUrl, ctaText } = this.props;

    return (
      <div className={s.cardPairItem}>
        {image &&
          <div className={s.cardPairItem__imageWrap}>
            <div className={s.cardPairItem__image}>
              <Image
                transition="scale"
                src={image.src}
                alt={image.alt || ''}
                width={image.width}
                height={image.height}
              />
            </div>
          </div>
        }

        <div className={s.cardPairItem__content}>
          {heading && (
            <h3 className={s.cardPairItem__heading}>
              {heading}
            </h3>
          )}

          {text && (
            <p className={s.cardPairItem__text}>
              {text}
            </p>
          )}

          {ctaUrl && (
          <div className={s.cardPairItem__button}>
            <Button to={ctaUrl} color="transparent">
              {ctaText || 'Read more'}
            </Button>
          </div>)}

        </div>
      </div>
    );
  }
}
